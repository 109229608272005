.login {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 90%;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  height: 95vh;

  h1 {
    border: solid 10px black;
    padding: 5px;
    width: 300px;
    font-size: 70px;
    box-sizing: border-box;
  }

  h2 {
    font-size: 25px;
    margin-top: 15px;
    margin-left: 110px;
  }

  .btn {
    margin-top: 50px;
    margin-left: 10px;
  }
}
