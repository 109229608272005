.flex-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.filter-bar {
  font-size: 16px;

  .search-input {
    margin: 20px 0;
    width: 65%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  select,
  input {
    font-size: 16px;
  }

  .keywords-input {
    display: flex;
    flex-direction: row;

    input {
      margin-left: 10px;
    }
  }

  .keyword-filters {
    display: flex;
    margin: 0;
    padding: 0;

    li {
      padding: 3px;
      font-size: 12px;
      margin-left: 10px;
      list-style: none;
      background: black;
      color: white;
      border: solid 1px white;
    }
  }

  .remove-keyword {
    cursor: pointer;
  }
}
