@media screen and (max-width: 1000px) {
  .page-top {
    padding-left: 0;
    padding-right: 0;

    nav .reversed {
      display: none;
    }
  }

  .articles {
    justify-content: center !important;
  }

  .page-title h2 {
    display: none;
  }

  .form-input select {
    width: 100%;
    max-width: 300px;
  }

  .login {
    h1 {
      font-size: 50px !important;
      width: 225px !important;
    }

    h2 {
      margin-left: 0 !important;
      font-size: 20px !important;
    }

    .btn {
      margin-left: 0 !important;
    }
  }

  .flex-bar {
    flex-direction: column;
  }

  #filter-category {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filter-bar .search-input {
    width: 80%;
  }

  .container {
    width: 90%;

    h3 {
      text-align: center;
    }
  }

  #tags-radio {
    flex-direction: column;
  }

  #filter-category label {
    margin-right: 10px;
  }
}

@media screen and (max-width: 340px) {
  #filter-category label {
    margin-right: 5px;
  }
}
