body {
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.5em;
}

h2 {
  margin-left: 20px;
  font-weight: normal;
  font-size: 1.1em;
}

h1,
h2 {
  margin-bottom: 0;
  margin-top: 0;
}

input[type='text'] {
  display: block;
  width: 100%;
  height: 35px;
  border-radius: 3px;
  box-shadow: none;
  padding-left: 10px;
  border: solid 1px lighten(grey, 10%);
}

select,
textarea {
  display: block;
  padding: 5px;
  width: 300px;
  font-size: 16px;
}

.container {
  width: 80%;
  margin: 0 auto;
}

#filter-category {
  display: flex;

  label {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  span {
    margin-left: 5px;
  }

  .checkbox-parent {
    position: relative;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    border: 1px solid grey;
    border-radius: 2px;
    background-color: white;
  }

  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 7px;
  }
}

#tags-radio {
  display: flex;

  label {
    display: flex;
    margin-left: 10px;
  }
}
