.form-input {
  margin-top: 15px;

  &.form-input-inline {
    display: flex;

    & > label {
      margin-right: 15px;
    }

    label {
      width: auto;
    }
  }

  label {
    font-weight: bold;
    display: block;
    width: 200px;
    margin-bottom: 5px;
  }

  .hint-text {
    font-size: 11px;
  }
}

.required-field,
.error-message {
  color: $red;
}
