.page-title {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;

  h1 {
    border: solid 5px black;
    padding: 5px;
  }
}

.page-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 100px 10px 100px;
  border-bottom: solid 1px black;

  nav {
    display: flex;

    .reversed {
      margin-right: 20px;
    }

    a {
      text-transform: none;
    }
  }
}
