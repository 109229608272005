.articles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin-left: -18px;
}

.article {
  border: solid 1px black;
  width: 280px;
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 18px;
  list-style: none;
  padding-bottom: 10px;

  a {
    color: black;
    text-align: right;
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  button a {
    color: white;
  }

  img {
    width: 100%;
    max-width: 300px;
    display: block;
  }

  .article-content {
    padding: 0 10px;
  }

  .article-picture {
    height: 170px;
    background-size: cover;
  }

  .article-date {
    text-align: right;
    padding-right: 10px;
  }

  .article-title {
    font-size: 1.25em;
    padding: 0 5px;
    height: 80px;
  }

  .article-keywords {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    li {
      list-style: none;
      font-size: 13px;
      margin: 8px 5px 0 5px;
      color: white;
      padding: 3px 6px;
      border-radius: 3px;

      &:nth-child(1n) {
        background-color: $green;
      }

      &:nth-child(2n) {
        background-color: $purple;
      }

      &:nth-child(3n) {
        background-color: $orange;
      }
    }
  }
}
