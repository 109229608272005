button a {
  text-decoration: none;
  color: black;
  font-family: 'Montserrat', sans-serif;
}

.link-button,
.back-button {
  border: 0;
  background: none;
  padding: 0;
  margin: 15px 0;
  font-family: 'Montserrat', sans-serif;

  &.reversed {
    a {
      background-color: transparent;
      color: black;
    }
  }

  a {
    border-radius: 5px;
    background-color: $red;
    padding: 12px 15px;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    border: solid 1px $red;

    &:hover {
      transition: 0.5s;
      background-color: lighten($red, 10%);
    }
  }
}

.save-button,
.btn {
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;
  margin: 15px 0;
  border-radius: 5px;
  background-color: $red;
  padding: 12px 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: white;

  &:disabled {
    cursor: auto;
    background-color: lighten($red, 20%);

    &:hover {
      background-color: lighten($red, 20%);
    }
  }

  &:hover {
    transition: 0.5s;
    background-color: lighten($red, 10%);
  }

  &.add-button {
    margin: 0;
    margin-left: 5px;
    padding: 0 6px;
  }
}

.edit-button {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
